import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileSharedService {
  private userDataSource = new BehaviorSubject<boolean>(false);
  userDetail = this.userDataSource.asObservable();

  updateUserDetail() {
    this.userDataSource.next(true);
  }
}
