import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';
import * as moment from 'moment';
import { PAGE_SIZES } from '../../constants/helpers.const';

@Component({
  selector: 'app-grid-filter',
  templateUrl: './grid-filter.component.html',
  styleUrls: ['./grid-filter.component.scss'],
})
export class GridFilterComponent implements OnInit, OnDestroy, OnChanges {
  pageSizes = PAGE_SIZES;
  form: FormGroup = this.fb.group({
    bank: [],
    account: [],
    clutch: [],
    ccy: [],
    search: [],
    range: [],
    month: [],
    year: [],
    startDate: [],
    endDate: [],
    perPage: [50],
    type: [],
  });

  private subscriptions = new Subscription();

  @Input() selectedClutch = '';
  @Input() clutchList: Array<{ id: string; name: string }> = [];
  @Input() ccyList: Array<{ id: string; name: string }> = [];
  @Input() selectedCcy = '';
  @Input() currentFilter = '';
  @Input() selectedMonth: any;
  @Input() selectedFinancialYear: any;
  @Input() financialList: Array<{ id: string; name: string }> = [];
  @Input() monthList: Array<{ id: string; date: string }> = [];
  @Input() banksList: Array<{ name: string }> = [];
  @Input() accountsList: Array<{ account_no: string; bank_code: string; ccy: string }> = [];
  @Input() tradeTransactionList: Array<{ id: string; name: string }> = [];
  @Input() dateRange: any;
  @Input() selectedRecordPerPage: any;
  @Input() label = '';
  @Input() showNewButton = false;
  @Input() filtersMultiple = true;
  @Input() rangeSelector = true;
  @Input() isSearch = true;
  disabled: boolean;

  @Input() set loading(val: boolean) {
    this.disabled = val;
    if (val) {
      this.form.disable({ onlySelf: true, emitEvent: false });
    } else {
      this.form.enable({ onlySelf: true, emitEvent: false });
    }
  }

  @Input() set savedFilterModel(value: Partial<any>) {
    if (value) {
      this.form.patchValue(value, { emitEvent: false, onlySelf: true });
    }
  }

  @Output() saveFilterData = new EventEmitter<any>();
  @Output() refreshPage = new EventEmitter<void>();
  @Output() createNew = new EventEmitter<string>();

  @Output() formChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() export: EventEmitter<any> = new EventEmitter<any>();
  @Output() print: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.subscriptions.add(
      this.form.valueChanges.pipe(debounceTime(700)).subscribe((value) => {
        const filter = {};
        const newValue = {
          ...value,
          startDate: value?.startDate ? moment(value.startDate[0]).format('YYYY-MM-DD') : null,
          endDate: value?.startDate ? moment(value?.startDate[1]).format('YYYY-MM-DD') : null,
        };
        for (const valueKey in newValue) {
          (filter as any)[valueKey] = newValue[valueKey];
        }
        this.formChange.emit(filter);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.filtersMultiple && this.form.controls['bank'] && this.form.controls['account']) {
      const bankControl = this.form.controls['bank'];
      bankControl.disable({ onlySelf: true, emitEvent: false });
      this.subscriptions.add(
        this.form.controls['account'].valueChanges.subscribe((value) => {
          const bankCode = this.accountsList.find((val) => val.account_no === value)?.bank_code;
          bankControl.setValue(bankCode, { onlySelf: true, emitEvent: false });
        }),
      );
    }
  }

  onShowData(range: string) {
    this.currentFilter = range;
    this.form.patchValue(
      {
        month: '',
        year: '',
        startDate: '',
        endDate: '',
      },
      { emitEvent: range === 'All' },
    );
  }

  openAddNewModal(type: string): void {
    this.createNew.emit(type);
  }

  resetFormGroup(): void {
    this.form.reset({ perPage: [this.form.value.perPage] });
    this.onShowData('All');
    this.refreshPage.emit();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
