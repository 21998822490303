import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'bi bi-speedometer2',
    class: '',
    extralink: false,
    image: './assets/images/icon/dashboard.svg',
    submenu: [],
  },
  {
    path: '/statements',
    title: 'Investors',
    icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
    class: '',
    extralink: false,
    image: './assets/images/icon/trade.svg',
    submenu: [], // Submenu of the submenu item if needed
  },
  {
    path: '/add-investor',
    title: 'Add Investor',
    icon: 'bi bi-speedometer2',
    class: '',
    extralink: false,
    image: './assets/images/icon/add-investment.svg',
    submenu: [],
  },
  {
    path: '',
    title: 'Add Investment',
    icon: 'bi bi-speedometer2',
    class: '',
    extralink: false,
    image: './assets/images/icon/add-investment.svg',
    submenu: [],
  },
  {
    path: '',
    title: 'Make Withdrawal',
    icon: 'bi bi-speedometer2',
    class: '',
    extralink: false,
    image: './assets/images/icon/withdraw-funds.svg',
    submenu: [],
  },

  {
    path: '/commission',
    title: 'Commissions',
    icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
    class: '',
    extralink: false,
    image: './assets/images/icon/trade.svg',
    submenu: [], // Submenu of the submenu item if needed
  },
  {
    path: '/profile',
    title: 'My Profile',
    icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
    class: '',
    extralink: false,
    image: './assets/images/icon/person.svg',
    submenu: [], // Submenu of the submenu item if needed
  },
  {
    path: '/bank',
    title: 'My Bank Details',
    icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
    class: '',
    extralink: false,
    image: './assets/images/icon/cash.svg',
    submenu: [], // Submenu of the submenu item if needed
  },
  {
    path: '/password',
    title: 'Change Password',
    icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
    class: '',
    extralink: false,
    image: './assets/images/icon/lock.svg',
    submenu: [], // Submenu of the submenu item if needed
  },
];

