<div 
     class="modal fade show custom_popup custom_popup_responsive"
     tabindex="-1"
     role="dialog"
>
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header justify-content-between">
                <h5 class="modal-title">
                   Add Investment
                </h5>
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        (click)="closeModal()"
                >
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body padding-model form-editing">
              
<div class="row">
    <div class="col-lg-12 p-0">
        <div class="card w-100">
            <div class="card">
                <form
                    *ngIf="investmentForm"
                    [formGroup]="investmentForm"
                    (ngSubmit)="onSubmit()"
                >
                    <div class="row">

                        <div style="width: 200px;" class="field-groups" >
                            <div class="mt-3">
                                <label for="investorId" class="form-title">
                                    <span class="required">*</span> Investor
                                    <span
                                        class="tooltip-ng ms-0"
                                        placement="bottom"
                                        ngbTooltip="{{ tooltipData.investorId }}">
                                        <i class="bi bi-question-circle"></i>
                                    </span>
                                </label>
                                <ng-select class="custom"
                                           id="investorId"
                                           [style.flex]="1"
                                           [items]="investors"
                                           bindLabel="name"
                                           bindValue="id"
                                           placeholder="All"
                                           formControlName="investorId"
                                           (change)="onSelect($event)"
                                           >
                                           <ng-template ng-option-tmp let-item="item">
                                            <span [title]="item.name">{{ item.name }}</span>
                                          </ng-template>     
                                </ng-select>                           
                            </div>

                            <div *ngIf="isSubmitted && investmentForm.controls['investorId'].invalid">
                                <small class="text-danger" *ngIf="investmentForm.controls['investorId'].errors?.['required']">
                                    <label for="" class="error">
                                        <img src="assets/images/icon/error.png" class="error-icon" alt="">
                                        Data is Required.
                                    </label>
                                </small>
                              </div>

                        </div>

                        <div  style="width: 57px;" class="field-groups custom-margin-1 align-items-center daterange_sec select_bank_filter" *ngIf="investmentForm.get('transactionType')?.value === 'Receipt'">
                            <div class="mt-3">
                                <label for="ccy" class="form-title text-nowrap "><span class="required">*</span> Ccy<span
                                    class="tooltip-ng"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData.ccy }}"
                                ><i class="bi bi-question-circle"></i></span
                                ></label>
                                <select
                                    class="custom-form-select border-radius-100"
                                    id="ccy"
                                    formControlName="ccy"
                                >
                                <option value="" selected="true" >All</option>
                                    <option *ngFor="let ccy of allCcyData" [value]="ccy.id">
                                        {{ ccy.name }}
                                    </option>
                                </select>
                            </div>
                         
                        </div>
                        <div style="width: 95px;" class="field-groups">
                            <div class="mt-3">
                                <label for="faceValue" class="form-title text-nowrap"
                                ><span class="required">*</span> Face Value
                                    <span
                                        class="tooltip-ng ms-0"
                                        placement="bottom"
                                        ngbTooltip="{{ tooltipData.faceValue }}"
                                    ><i class="bi bi-question-circle"></i></span
                                    ></label>
                                <input
                                    id="faceValue"
                                    [appOnlyNumber]="true"
                                    type="text"
                                    class="form-control ft-width numberText"
                                    placeholder=""
                                    formControlName="faceValue"
                                    maxlength="13"
                                />
                            </div>
                        </div>

                        <div style="width: 60px;" class="field-groups"  *ngIf="investmentForm.get('transactionType')?.value === 'Receipt'">
                            <div class="mt-3">
                                <label for="months" class="form-title text-nowrap"><span class="required">*</span> Months
                                    <span
                                        class="tooltip-ng ms-0"
                                        placement="bottom"
                                        ngbTooltip="{{ tooltipData.months }}"
                                    ><i class="bi bi-question-circle"></i></span
                                    ></label>
                                <input
                                    id="months"
                                    appOnlyNumber
                                    type="text"
                                    class="form-control ft-width numberText"
                                    placeholder=""
                                    formControlName="months"
                                    maxlength="4"
                                       />
                            </div>
                        </div>

                        <div style="width: 65px;"  class="field-groups custom-margin-1 align-items-center daterange_sec select_bank_filter">
                            <div class="mt-3">
                                <label for="type" class="form-title text-nowrap"><span class="required">*</span> Type<span
                                    class="tooltip-ng"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData.type }}"
                                ><i class="bi bi-question-circle"></i></span
                                ></label>
                                <select
                                    class="custom-form-select border-radius-100"
                                    id="type"
                                    formControlName="type"
                                >
                                <option value="" selected="true">All</option>
                                    <option *ngFor="let tt of type" [value]="tt.id">
                                        {{ tt.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div style="width: 80px;" class="field-groups">
                            <div class="mt-3">
                                <label for="product" class="form-title text-nowrap"
                                ><span class="required">*</span> Product<span
                                    class="tooltip-ng"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData.product }}"
                                ><i class="bi bi-question-circle"></i></span
                                ></label>
                                <select
                                    class="custom-form-select border-radius-100"
                                    id="product"
                                    formControlName="product"
                                >
                                <option value="" selected="true" >All</option>
                                    <option *ngFor="let prod of product" [value]="prod.id">
                                        {{ prod.name }}
                                    </option>
                                </select>
                            </div>  
                        </div>                      

                        <div style="width: 137px;" class="field-groups">
                            <div class="mt-3">
                                <label for="date" class="form-title"><span class="required">*</span> Date
                                    <span
                                        class="tooltip-ng ms-0"
                                        placement="bottom"
                                        ngbTooltip="{{ tooltipData.date }}">
                                        <i class="bi bi-question-circle"></i>
                                    </span>
                                </label>
                                <div class="input-group">
                                    <input
                                        id="date"
                                        class="form-control"
                                        placeholder="YYYY-MM-DD"
                                        name="d3"
                                        formControlName="date"
                                        ngbDatepicker
                                        #d3="ngbDatepicker"
                                    />
                                    <button
                                        class="btn btn-outline-secondary btn-calendar-icon"
                                        (click)="d3.toggle()"
                                        type="button"
                                    >
                                        <img src="./assets/images/icon/calendar-icon.svg" alt="" />
                                    </button>
                                </div>
                            </div> 
                        </div>
     
                        <div class="col-md-12">
                            <div class="mt-3">
                                <label for="comments" class="form-title">Comments
                                    <span
                                        class="tooltip-ng ms-0"
                                        placement="bottom"
                                        ngbTooltip="{{ tooltipData.comments }}"
                                    ><i class="bi bi-question-circle"></i></span
                                    ></label>
                                <textarea
                                    id="comments"
                                    type="text"
                                    class="form-control"
                                    placeholder="Type somthing here..."
                                    formControlName="comments"
                                    row="8"
                                    maxlength="512"
                                >
                                </textarea>
                            </div>
                        </div>
                        
                        <div class="col-12 bottom-fixed">
                            <hr />
                            <div class="d-flex justify-content-center mt-3">
                                <button [disabled]="investmentForm.invalid"  type="submit" class="btn btn-light me-3 btn-custom-footer save_btn">
                                    Save
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-light btn-custom-footer cancel_btn"
                                    data-dismiss="modal"
                                    (click)="closeModal()"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
            </div>
        </div>
    </div>
</div>
