import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as moment from 'moment';
import { FS_PDF_Data } from 'src/app/financial-statement/common/interfaces/fs_pdf_interface';
import { PercentValueFormatter4 } from '../helpers/percent-value-formatter';
import { numberValueFormatter } from '../helpers/number-value-formatter';

@Component({
  selector: 'app-html-to-pdf',
  templateUrl: './html-to-pdf.component.html',
  styleUrls: ['./html-to-pdf.component.scss'],
})
export class HtmlToPdfComponent {
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  typeDemand: any[] = [];
  typeFixed: any[] = [];
  typeTerm: any[] = [];
  savedData: any[] = [];
  fs_Pdf_Data: FS_PDF_Data;
  isSingleType = true;
  logoUrl = '../../../assets/images/logos/logo-investabill-dark.png';
  stampUrl = '../../../assets/images/logos/stamp-bank-statement.png';
  @Output() pdfGenerated: EventEmitter<boolean> = new EventEmitter<boolean>();

  public async downloadAsPDF(fileName: string, fs_Pdf_Data: any) {
    const data = this.pdfTable.nativeElement;
    this.fs_Pdf_Data = fs_Pdf_Data;
    this.isSingleType = this.fs_Pdf_Data.isSingleType;
    this.typeDemand = fs_Pdf_Data.dataList.typeDemand;
    this.typeFixed = fs_Pdf_Data.dataList.typeFixed;
    this.typeTerm = fs_Pdf_Data.dataList.typeTerm;
    const allData = fs_Pdf_Data.dataList.typeDemand.concat(fs_Pdf_Data.dataList.typeFixed).concat(fs_Pdf_Data.dataList.typeTerm);
    if (allData.length === 0) return;
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3,
    };
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 15;
    const headerHeight = 20;
    const footerHeight = 40;
    const effectivePageHeight = pageHeight - headerHeight - footerHeight - 2 * margin;
    const renderPage = async (htmlContent: string, pageNumber: number, totalPages: number) => {
      data.innerHTML = htmlContent;
      data.style.width = '1150px';
      const canvas = await html2canvas(data, options);
      const imgWidth = doc.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL('image/jpeg', 0.8);
      const position = margin + headerHeight;
      doc.addImage(imgData, 'JPEG', margin, position, imgWidth - 2 * margin, Math.min(effectivePageHeight, imgHeight));
      this.addHeader(doc);
      this.addFooter(doc, pageNumber, totalPages);
    };

    const calculateTotalPages = async (allData: any[]) => {
      let pageNumber = 1;
      let totalPages = 0;
      const isFirst25DemandEUR = allData
      .slice(0, 25)
      .filter((record) =>
        ['Demand', 'Fixed', 'Term'].includes(record.type) && record.ccy === 'EUR'
      ).length > 0;

    const isCombinedLength20 = allData
      .filter((record) =>
        ['Demand', 'Fixed', 'Term'].includes(record.type) && record.ccy === 'EUR'
      ).length === 20;

    const recordsPerPage = isCombinedLength20
      ? 18
      : isFirst25DemandEUR
        ? 23
        : 20;
    
      for (let index = 0; index < allData.length; ) {
        const currentRecordsPerPage = pageNumber === 1 ? recordsPerPage : 25;
        index += currentRecordsPerPage;
  
        totalPages++;
        pageNumber++;
      }
    
      return totalPages;
    };
    
    
    const totalPages = await calculateTotalPages(allData);
    const generatePDF = async (allData: any[]) => {
      let pageNumber = 1;

      const isFirst25DemandEUR = allData
        .slice(0, 25)
        .filter((record) =>
          ['Demand', 'Fixed', 'Term'].includes(record.type) && record.ccy === 'EUR'
        ).length > 0;

      const isCombinedLength20 = allData
        .filter((record) =>
          ['Demand', 'Fixed', 'Term'].includes(record.type) && record.ccy === 'EUR'
        ).length === 20;

      const recordsPerPage = isCombinedLength20
        ? 18
        : isFirst25DemandEUR
          ? 23
          : 20;


    
      for (let index = 0; index < allData.length; ) {

        const currentRecordsPerPage = pageNumber === 1 ? recordsPerPage : 25;
    

        const pagedData: any[] = [];
        while (index < allData.length && pagedData.length < currentRecordsPerPage) {
          pagedData.push(allData[index]);
          index++;
        }
    

    
        // Render HTML for the current page
        const htmlContent = this.htmlTable(allData, pagedData, pageNumber);
        await renderPage(htmlContent, pageNumber,totalPages);
    
        if (index < allData.length) {
          doc.addPage();
          pageNumber++;
        }
      }
    };
    
    

    await generatePDF(allData);
    const pdfBlob = doc.output('blob');
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(pdfBlob);
    downloadLink.download = `${fileName}.pdf`;
    downloadLink.click();
    window.open(URL.createObjectURL(pdfBlob), '_blank');
    this.pdfGenerated.emit(true);
  }


  getTotalYieldByType(data: any[], type: any) {
    return data
      .filter(item => item.type === type) 
      .reduce((sum: number, item: any) => sum + (item.yield || 0), 0);
  }


  findLatestRecord(rowData: any) {
    let latestRecord = null;
    let latestDate = null;
    for (let i = rowData.length - 1; i >= 0; i--) {
      const record = rowData[i];
      if (record.maturityDate === null || record.maturityDate == 'null') {
        continue;
      }
      const currentDate = new Date(record.date);
      if (!latestDate || currentDate > latestDate) {
        latestDate = currentDate;
        latestRecord = record;
      }
    }
    return latestRecord;
  }

  htmlTable(allData:any,typeList: any, pageNumber: number) {
    const { userData } = this.fs_Pdf_Data;

    const totalYieldDemand = this.getTotalYieldByType(allData, 'Demand');
    const totalYieldFixed = this.getTotalYieldByType(allData, 'Fixed');
    const totalYieldTerm = this.getTotalYieldByType(allData, 'Term');

    const demandData = allData.filter((x: { type: string }) => x.type === 'Demand');
    const fixedData = allData.filter((x: { type: string }) => x.type === 'Fixed');
    const termData = allData.filter((x: { type: string }) => x.type === 'Term');
    
    const demandMaturityDate = this.findLatestRecord(demandData)?.maturityDate;
    const fixedMaturityDate = this.findLatestRecord(fixedData)?.maturityDate;
    const termMaturityDate = this.findLatestRecord(termData)?.maturityDate;

    const header = `
      <tr>
        <td colspan="3" style="text-align: center;"><h1 style="font-weight: 600;">Financial Statement</h1></td>
      </tr>
      <tr>
        <td height="30px"></td>
      </tr>
      <tr>
        <td style="vertical-align: top;">
          <div class="account-info">
            <h4>${userData.name}</h4>
            <p>${userData.address ? userData.address : ''}</p>
          </div>
        </td>
        <td>
          <table class="statement-header-table">
            <tr>
              <td>
                <p>Bank Name</p>
                <p>SWIFT BIC</p>
                <p>IBAN</p>
                <p style="font-weight: 600; margin-top: 20px;">IAA</p>
                <p style="font-weight: 600;">RI</p>
              </td>
              <td>
                <p>:</p>
                <p>:</p>
                <p>:</p>
                <p style="font-weight: 600; margin-top: 20px">:</p>
                <p style="font-weight: 600;">:</p>
              </td>
              <td>
                <p>&nbsp;${userData.bankName}</p>
                <p>&nbsp;${userData.swiftBic}</p>
                <p>&nbsp;${userData.iBan}</p>
                <p style="font-weight: 600; margin-top: 20px">&nbsp;${userData.intermediaryId_IAA}</p>
                <p style="font-weight: 600;">&nbsp;${userData.investorId_RI}</p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td style="height: 20px;"></td>
      </tr>
    `;

    return `
      <header>
        <table style="width: 100%;">
          <tr>
            <td style="text-align: left; vertical-align: top;"></td>
            <td style="float: right;">
              <p style="line-height: 22px; font-size: 17px; font-weight: 600; margin-bottom: 2px;">Credebt Exchange Limited</p>
              <p style="line-height: 22px; font-size: 15px;">15A Baggotrath Place, 15 - 16<br> Lower Baggot Street,<br>Dublin D02 NX49, Ireland</p>
              <p style="line-height: 22px; font-size: 15px;">Phone : +353 (1) 685-3600</p>
              <p style="line-height: 22px; font-size: 15px;">Email: yield@investabill.com</p>
            </td>
          </tr>
          <tr>
            <td height="25px"></td>
          </tr>
          ${pageNumber === 1 ? header : ''}
        </table>
        <hr>
      </header>
      <section class="transactions">
        <div class="data-table-outer">
          ${[...new Set(typeList.map((item: { type: string; }) => item.type))].map((type, index) => {
            let summaryDisplayed = false; 
            return [...new Set(typeList.filter((item: { type: string }) => item.type === type).map((item:any) => item.ccy))].map(ccy => {
              const filteredItems = typeList.filter((item: any) => item.type === type && item.ccy === ccy);
              const filteredItemsBytype = allData.filter((item: any) => item.type === type && item.ccy === ccy);
              const totalYield = filteredItemsBytype.reduce((sum: number, item: any) => sum + (item.yield || 0), 0);
              const totalBalance = filteredItemsBytype.length > 0 ? filteredItemsBytype[filteredItemsBytype.length - 1].balance || 0 : 0;
              const lastIndexId = filteredItemsBytype.length > 0 ? filteredItemsBytype[filteredItemsBytype.length - 1].id || 0 : 0;
              let yieldData:any;
              let maturityDate:any;
              if (filteredItems.length > 0) {
   
                let summaryToDisplay:any = '';
            
                if (!summaryDisplayed) {
                  summaryToDisplay = type;
                  summaryDisplayed = true;
                }
                if (summaryToDisplay == 'Demand') {
                  yieldData = totalYieldDemand;
                  maturityDate=demandMaturityDate
                }
                else if (summaryToDisplay == 'Term') {
                  yieldData = totalYieldTerm;
                  maturityDate=fixedMaturityDate
                }
                else if (summaryToDisplay == 'Fixed') {
                  yieldData = totalYieldFixed;
                  maturityDate=termMaturityDate;
                }          
 
                  this.savedData.push({yieldData:yieldData,maturityDate:maturityDate})

                   const isSummaryExists= this.savedData.filter((x=>x.yieldData==yieldData && x.maturityDate==maturityDate))

                   if(isSummaryExists.length>1){
                    summaryToDisplay='';
                   }

                return `

                  <table class="data-table">
                    <thead>
                      <tr>
                        <th colspan="10">Detailed Statement</th>
                      </tr>
                      <tr>
                       
                        <th style="width:110px;">Date</th>
                        <th style="width:100px;">Type</th>
                        <th style="width:30px;">Mos</th>
                        <th style="width:130px;">Maturity Date</th>
                        <th style="width:90px;">Buy Rate</th>
                        <th style="width:50px;">Ccy</th>
                        <th>Payments</th>
                        <th>Receipts</th>
                        <th style="min-width:120px;">Yield</th>
                        <th style="min-width:120px;">Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${filteredItems.map((item: any, index: number) => `
                        <tr>
                      
                          <td style="color: #000;">${new Date(item.date).toISOString().split('T')[0]}</td>
                          <td style="color: #000;">${item.transactionType === 'Adjustment' ? 'Adjustment' : (item.type || '-')}</td>
                          <td style="color: #000;">${item.months || '-'}</td>
                          <td style="color: #000;">${item.maturityDate ? moment(item.maturityDate).format('YYYY-MM-DD') : '-'}</td>
                          <td style="color: #000;" class="text-center">${item.buyRate ? PercentValueFormatter4({ value: item.buyRate }) : '-'}</td>
                          <td style="color: #000;">${item.ccy || ''}</td>
                          <td style="color: #000;" class="text-right">${item.paymentAmount ? numberValueFormatter({ value: item.paymentAmount }) : '-'}</td>
                          <td style="color: #000;" class="text-right">${item.receiptAmount ? numberValueFormatter({ value: item.receiptAmount }) : '-'}</td>
                          <td style="color: #000;" class="text-right">${item.yield ? numberValueFormatter({ value: item.yield }) : '-'}</td>
                          <td style="color: #000;" class="text-right">${item.balance ? numberValueFormatter({ value: item.balance }) : '-'}</td>
                        </tr>

                  ${index === filteredItems.length - 1 && item.id === lastIndexId

                        ? `<tr>
                            <td colspan="9" style="text-align: right; padding-right: 5px; font-weight: 700; font-size:18px;">Total Yield : &nbsp; ${numberValueFormatter({ value: totalYield })}</td>
                            <td style="text-align: right; padding-right: 5px; font-weight: 700; font-size:18px;">${numberValueFormatter({ value: totalBalance })}</td>
                          </tr>`
                        : ''
                      }
                      
                      `).join('')}
                    </tbody>
                  </table>
                  <br>
                `;
              }
              return '';
            }).join('');
          }).join('')}
          
          
          
          </table>
        </div>
      </section>
      <style>
        img, ul, h1, h2, h3, h4, h5, h6, p { margin: 0px; padding: 0px; }
        * { margin: 0px; padding: 0px; }
        a { text-decoration: none; transition: all .5s ease-in-out; }
        a:hover { text-decoration: none; }
        .statement { width: 1050px; margin: auto; background: #fff; padding: 10px 40px; position: relative; }
        h1 { font-size: 24px; font-weight: 500; }
        .bank-name, .statement-date { margin: 5px 0; }
        .account-info h4 { font-size: 22px; font-weight: 700; }
        .account-info p { font-size: 16px; margin-top: 8px; }
        .data-table-outer { margin-top: 20px; margin-bottom: 40px; }
        .data-table.overide-statement { margin-bottom: 20px; }
        .data-table { width: 100%; border-collapse: collapse; border: 1px solid #3d4856; }
        .data-table tr:nth-child(even) { background-color: #f2f2f2; }
        .data-table th { background: #3d4856; color: #fff; }
        .data-table, .data-table th, .data-table td { border: 1px solid #54667a; }
        .data-table th, .data-table td { padding: 5px 5px; text-align: center; font-size: 15px;}
        h2 { font-size: 22px; font-weight: 500; margin-bottom: 6px; }
        .statement-header-table { margin-top: 0; width: 350px; margin-left: auto; }
        .investor-detail p { padding-top: 8px; }
        .investor-detail.summery { padding-top: 12px; }
        .investor-detail.summery p { font-weight: 600; }
        .investor-detail p span { display: inline-block; width: 50px; }
        .statement-header-table p { margin-top: 8px; font-size: 16px; min-height: 18px; }
        .transactions { margin-top: 0px; padding-top: 0px; }
        .total-text { display: block; text-align: right; width: 112px; }
        .data-table .text-right { text-align: right; }
        .statement.statement-2 .data-table-outer { height: unset; }
        .stamp-and-date { background: rgba(235, 255, 241, 1); }
        .statement .stamp-and-date { background-color: #fff; text-align: right; margin-top: 60px; }
        .statement .stamp-and-date p { font-size: 16px; padding-top: 10px; font-weight: 600; }
        .pagination { padding: 0px 0px; position: absolute; bottom: 0; right: 0; }
        .pagination ul { display: flex; align-items: center; justify-content: end; list-style: none; column-gap: 12px; }
        .pagination ul li a { color: #000; }
        .white-space { height: 250px; }
      </style>
    `;
  }
  addHeader(doc: jsPDF) {
    const margin = 15;
    const imageWidth = 200;
    const imageHeight = 50;
    doc.addImage(this.logoUrl, 'PNG', margin, margin, imageWidth, imageHeight);
  }

  addFooter(doc: jsPDF, pageNumber: number, totalPages: number) {
    const margin = 2;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const currentDate = moment().format('YYYY-MM-DD'); 
    doc.setFontSize(10);
    doc.text(`Page ${pageNumber} of ${totalPages}`, pageWidth - margin - 70, pageHeight - margin - 20);

    // Add stamp to the last page
    if (pageNumber === totalPages) {
      const stampWidth = 50;
      const stampHeight = 50;
      const stampX = pageWidth - margin - stampWidth;
      const stampY = pageHeight - margin - stampHeight;
      doc.addImage(this.stampUrl, 'PNG', stampX - 20, stampY - 60, stampWidth, stampHeight);

      // Add the date below the stamp image
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text(currentDate, stampX - 20, stampY - 50 + stampHeight + 10);
    }
  }
}
