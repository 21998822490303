import { Component, EventEmitter, Output } from '@angular/core';
import { NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [NgbDropdownModule],
  templateUrl: './navigation.component.html',
})
export class NavigationComponent {
  @Output() toggleSidebar = new EventEmitter<void>();
  userName: string | null = localStorage.getItem('name');

  constructor(
    private modalService: NgbModal,
    public router: Router,
  ) {}

}
