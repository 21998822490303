

<div 
     class="modal fade show custom_popup"
     tabindex="-1"
     role="dialog"
>
        <div class="modal-dialog" role="document" [ngClass]="isPerson ? 'width-573' : 'width-563'">
        <div class="modal-content add-investor">
            <div class="modal-header justify-content-between">
              <div class="toggle-header d-flex align-items-center gap-2">
                <h5 class="modal-title">
                   Add Investor
                </h5>
                <ng-toggle
                [value]="toggleSettings.values"
                [width]="isPerson ? 72 : 105"
                [height]="20"
                [labels]="toggleSettings.labels"
                [switchColor]="toggleSettings.switchColor"
                [color]="toggleSettings.color"
                [values]="toggleSettings.values"
                [fontColor]="toggleSettings.fontColor"
                (valueChange)="toggleChange($event)"
          />
          </div>
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        (click)="closeModal()"
                >
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body padding-model form-editing">
              
              <div class="row">
                <div class="col-lg-12 p-0">
                    <div class="card w-100">
                        <div class="card">
                   <form [formGroup]="investorForm" (ngSubmit)="onSubmit()" class="pl-2">
                      <div class="row">

                        <div *ngIf="!isPerson" class="mb-3 field-group" style="width: 272px;">
                          <label for="organization" class="form-title"><span class="required">*</span> Organization
                            <span
                              class="tooltip-ng ms-0"
                              placement="bottom"
                              ngbTooltip="{{ tooltipData.organization }}"
                            ><i class="bi bi-question-circle"></i></span
                            ></label>
                          <input type="text" class="form-control" formControlName="firstName" id="organization" />
                        </div>
                          <div *ngIf="isPerson" class="mb-3 field-group" style="width: 125px;">
                            <label for="firstName" class="form-title"
                            > <span class="required">*</span> First Name
                            <span
                              class="tooltip-ng ms-0"
                              placement="bottom"
                              ngbTooltip="{{ tooltipData.firstName }}"
                              ><i class="bi bi-question-circle"></i></span
                          ></label>
                            <input type="text" class="form-control" formControlName="firstName"
                              id="firstName" />
                          </div>
              
              
                          <div *ngIf="isPerson" class="mb-3 field-group" style="width: 125px;">
                            <label for="lastName" class="form-title"
                            > <span class="required">*</span> Last Name
                            <span
                              class="tooltip-ng ms-0"
                              placement="bottom"
                              ngbTooltip="{{ tooltipData.lastName }}"
                              ><i class="bi bi-question-circle"></i></span
                          ></label>

                            <input type="text" class="form-control" formControlName="lastName"
                              id="lastName" />
                          </div>
              
                          <div class="mb-3 field-group" style="width: 145px;">
                            <label for="email" class="form-title"
                            > Email
                            <span
                              class="tooltip-ng ms-0"
                              placement="bottom"
                              ngbTooltip="{{ tooltipData.email }}"
                              ><i class="bi bi-question-circle"></i></span
                          ></label>
                            <input type="text" class="form-control" formControlName="email" id="email" />
                          </div>
              
                          <div *ngIf="isPerson" class="mb-3 field-group" style="width: 148px;">
                            <label for="dob" class="form-title"
                            > Date of Birth
                            <span
                              class="tooltip-ng ms-0"
                              placement="bottom"
                              ngbTooltip="{{ tooltipData.dob }}"
                              ><i class="bi bi-question-circle"></i></span
                          ></label>
                            <div class="input-group birth_date">
                              <input type="text" class="form-control" placeholder="YYYY-MM-DD" name="d1" formControlName="birthDate"
                                ngbDatepicker #d1="ngbDatepicker" />
                              <button class="btn btn-outline-secondary btn-calendar-icon" (click)="d1.toggle()" type="button"><img
                                  src="./assets/images/icon/calendar-icon.svg"  alt=""/></button>
                            </div>
                          </div>

                            <div class="mb-3 field-group" style="width: 110px;">
                              <label for="lineOne" class="form-title"
                              > <span class="required">*</span> Address
                              <span
                                class="tooltip-ng ms-0"
                                placement="bottom"
                                ngbTooltip="{{ tooltipData.address }}"
                                ><i class="bi bi-question-circle"></i></span
                            ></label>
                              <input type="text" class="form-control"  formControlName="lineOne"
                                id="lineOne" />
                            </div>
  
                            <div class="mb-3 mt-1 field-group" style="width: 110px;">
                              <label for="lineTwo" class="form-title"> </label>
                              <input type="text" class="form-control"  formControlName="lineTwo"
                                id="lineTwo" />
                            </div>
                
                
                            <div class="mb-3 field-group" style="width:80px;">
                              <label for="city" class="form-title"
                              > <span class="required">*</span> City
                              <span
                                class="tooltip-ng ms-0"
                                placement="bottom"
                                ngbTooltip="{{ tooltipData.city }}"
                                ><i class="bi bi-question-circle"></i></span
                            ></label>
                              <input type="text" class="form-control"  formControlName="city" id="city" />
                            </div>
                
                            <div class="mb-3 field-group"  style="width: 80px;">
                              <label for="state" class="form-title"
                              ><span class="required">*</span> County
                              <span
                                class="tooltip-ng ms-0"
                                placement="bottom"
                                ngbTooltip="{{ tooltipData.state }}"
                                ><i class="bi bi-question-circle"></i></span
                            ></label>
                              <input type="text" class="form-control" formControlName="state" id="state" />
                            </div>
  
                            <div class="mb-3 field-group"  style="width: 83px;">
                              <label for="area" class="form-title"
                              > Area
                              <span
                                class="tooltip-ng ms-0"
                                placement="bottom"
                                ngbTooltip="{{ tooltipData.area }}"
                                ><i class="bi bi-question-circle"></i></span
                            ></label>
                              <input type="text" class="form-control" formControlName="area" id="area" />
                            </div>
  
                            <div class="mb-3 field-group" style="width: 80px;">
                              <label for="zipCode" class="form-title text-nowrap"
                              > <span class="required">*</span> Post code
                              <span
                                class="tooltip-ng ms-0"
                                placement="bottom"
                                ngbTooltip="{{ tooltipData.postCode }}"
                                ><i class="bi bi-question-circle"></i></span
                            ></label>
                              <input type="text" class="form-control text-left"  formControlName="zipCode"
                                id="zipCode" />
                            </div>
  
                            <div class="mb-3 field-group" style="width: 100px;">
                              <label for="country" class="form-title"
                              > <span class="required">*</span> Country
                              <span
                                class="tooltip-ng ms-0"
                                placement="bottom"
                                ngbTooltip="{{ tooltipData.country }}"
                                ><i class="bi bi-question-circle"></i></span
                            ></label>
                            <div class="input-group">
                              <select class="custom-form-select border-radius-100" id="country" formControlName="country" (change)="onSelectCountry($event)">
                                <option value="" selected="true" >All</option>
                                <option *ngFor="let country of countryList" [value]="country.name">
                                  {{ country.name }}
                                </option>
                              </select>
  
                            </div>
                            </div>
                            
                                <div class="mb-3 field-group" style="width: 154px;">
                                  <label for="home" class="form-title text-nowrap"
                                  >  Home Phone
                                  <span
                                    class="tooltip-ng ms-0"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData.homePNumber }}"
                                    ><i class="bi bi-question-circle"></i></span
                                ></label>
                                <input type="text" appFormatPhone class="form-control text-left"  formControlName="home" id="home"  />
                                </div>
                    
                                <div class="mb-3 field-group" style="width: 154px;">
                                  <label for="office" class="form-title text-nowrap"
                                  >  Office Phone
                                  <span
                                    class="tooltip-ng ms-0"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData.officePNumber }}"
                                    ><i class="bi bi-question-circle"></i></span
                                ></label>
                                  <input  type="text" appFormatPhone  class="form-control text-left" 
                                    formControlName="office" id="office" />
                                </div>
                    
                                <div class="mb-3 field-group" style="width: 135px;">
                                  <label for="mobile" class="form-title"
                                  >  Mobile Phone
                                  <span
                                    class="tooltip-ng ms-0"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData.mobileNumber }}"
                                    ><i class="bi bi-question-circle"></i></span
                                ></label>
                                  <input  type="text" appFormatPhone  class="form-control text-left" 
                                    formControlName="mobile" id="mobile" />
                                </div>
                                
                                <div  class="mb-3 field-group" style="width: 90px;"> 
                                    <label for="sortCode" class="form-title text-nowrap"
                                      > <span class="required" *ngIf="isSortCodeRequired" >*</span> Sort Code
                                      <span
                                        class="tooltip-ng ms-0"
                                        placement="bottom"
                                        ngbTooltip="{{ tooltipData.sortCode }}"
                                        ><i class="bi bi-question-circle"></i></span
                                    ></label>
                                    <input type="text" maxlength="14" class="form-control sd-width" placeholder="" formControlName="sortCode" id="sortCode" />
                                  </div>
                                
                                <div class="mb-3 field-group" style="width: 100px;">
                                    <label for="account" class="form-title"
                                      ><span class="required" *ngIf="isAccountRequired">*</span> Account
                                      <span
                                        class="tooltip-ng ms-0"
                                        placement="bottom"
                                        ngbTooltip="{{ tooltipData.account }}"
                                        ><i class="bi bi-question-circle"></i></span
                                    ></label>
                                    <input type="text" appOnlyNumber class="form-control" formControlName="account" id="account" />
                                </div>

                                <div  class="mb-3 field-group" style="width: 87px;">
                                      <label for="swift" class="form-title"
                                      > SWIFT BIC
                                          <span
                                              class="tooltip-ng ms-0"
                                              placement="bottom"
                                              ngbTooltip="{{ tooltipData.swift }}"
                                          ><i class="bi bi-question-circle"></i></span
                                          ></label>
                                      <input
                                          id="swift"
                                          type="text"
                                          class="form-control ft-width"
                                          placeholder=""
                                          formControlName="swiftBic"
                                          maxlength="8"
                                          style="text-transform: uppercase;"
                                      />
                              </div>
                            
                              <div  class="mb-3 field-group" style="width: 174px;">
                                      <label for="iban" class="form-title"
                                      ><span class="required" *ngIf="isIbanRequired">*</span> IBAN 
                                          <span
                                              class="tooltip-ng ms-0"
                                              placement="bottom"
                                              ngbTooltip="{{ tooltipData.iban }}"
                                          ><i class="bi bi-question-circle"></i></span
                                          ></label>
                                      <input
                                          id="iban"
                                          type="text"
                                          class="form-control ft-width"
                                          placeholder=""
                                          formControlName="iban"
                                          maxlength="34"
                                          style="text-transform: uppercase;"
                                      />
                              </div>
                            
                          
      
                  </div>
                <div class="col-12 bottom-fixed">
                  <hr class="my-2"/>
                  <div class="d-flex justify-content-center mt-3">
                      <button [disabled]="investorForm.invalid"  type="submit" class="btn btn-light me-3 btn-custom-footer save_btn">
                          Save
                      </button>
                      <button
                          type="button"
                          class="btn btn-light btn-custom-footer cancel_btn"
                          data-dismiss="modal"
                          (click)="closeModal()"
                      >
                          Cancel
                      </button>
                  </div>
              </div>
              
              </form>
            </div>
        </div>
    </div>
</div>
            </div>
        </div>
    </div>
</div>





