<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-start me-auto">
  <li class="nav-item">
      <a
            class="nav-link waves-effect waves-dark"
            (click)="toggleSidebar.emit()"

            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >

            <img alt="" src="./assets/images/icon/bar.svg">
          </a>
  </li>
  <li class="nav-item" style="display:none">
    <a class="nav-link waves-effect waves-dark" href="/dashboard"> Starter </a>
  </li>
  <li class="nav-item" style="display:none">
    <a class="nav-link waves-effect waves-dark" href="/about"> About </a>
  </li>

</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-end">
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
  <li class="nav-item" ngbDropdown placement="bottom-end">
    <a
      ngbDropdownToggle
      class="nav-link waves-effect waves-dark pro-pic"
      href="javascript:void(0)"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img
alt=""        src="assets/images/users/user1.jpg"
        alt="user"
        class="rounded-circle"
        width="31"
      />
      <span class="ms-2">{{userName}}</span>
    </a>
    <div class="dropdown-menu-right" ngbDropdownMenu>
     <!-- <a class="dropdown-item" href="javascript:void(0)"> My Profile</a>
      <a class="dropdown-item" href="javascript:void(0)"> My Balance</a>
      <a class="dropdown-item" href="javascript:void(0)"> Inbox</a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="javascript:void(0)"> Account Setting</a>
      <div class="dropdown-divider"></div>-->
      <a class="dropdown-item" href="javascript:void(0)" > Logout</a>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
</ul>
