export const SimpleNumberValueFormatter = (number: any, decimal = 2) => {
  const originalValue = +number;
  let formattedValue = '';

  if (+originalValue != 0 && originalValue != null) {
    formattedValue = originalValue?.toLocaleString('en-US', {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  }
  return formattedValue;
};

export const AdvancedNumberValueFormatter = ({ number, decimal = 2, sign }: { number: any; decimal?: number; sign?: string }) => {
  const formattedValue = SimpleNumberValueFormatter(number, decimal);
  const orginalValue = number;
  let changedValue: string;

  if (+number && number !== null) {
    changedValue =
      Math.abs(Number(orginalValue)) >= 1.0e9
        ? (Math.abs(Number(orginalValue)) / 1.0e9).toFixed(decimal) + (sign ? sign : ' b')
        : // Six Zeroes for Millions
          Math.abs(Number(orginalValue)) >= 1.0e6
          ? (Math.abs(Number(orginalValue)) / 1.0e6).toFixed(decimal) + (sign ? sign : ' m')
          : // Three Zeroes for Thousands
            Math.abs(Number(orginalValue)) >= 1.0e3
            ? (Math.abs(Number(orginalValue)) / 1.0e3).toFixed(decimal) + (sign ? sign : ' k')
            : // For values less than 1000, just return the number itself
              Math.abs(Number(orginalValue)).toFixed(decimal) + (sign ? sign : '');

    changedValue = orginalValue < 0 ? `-${changedValue}` : changedValue;
  } else {
    changedValue = '0';
  }
  return changedValue;
};
