import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getFormData } from 'src/app/shared/helpers/utils';
import { NgbActiveModal, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { WithdrawFundsFormProvider } from '../../withdrawFunds-form-provider';
import { WithdrawFundsHttpService } from '../../common/services/withdrawFunds-http.service';
import { WithdrawFund } from 'src/app/shared/interfaces/withdraw-fund-response.interface';
import { InvestmentsHttpService } from 'src/app/investments/common/services/investments-http.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { INTERMEDIARY_ID } from 'src/app/shared/enums/common.enum';
import { InvestorsHttpService } from 'src/app/investors/common/services/Investors-http.service';
import { InvestorsByIdResponseInterface } from 'src/app/shared/interfaces/Investors-response.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { InvestmentsResponseInterface } from 'src/app/shared/interfaces/investments-response.interface';

@Component({
  selector: 'app-withdraw-funds',
  templateUrl: './withdraw-funds.component.html',
  styleUrls: ['./withdraw-funds.component.scss'],
})
export class WithdrawFundsComponent implements OnInit {
  WithdrawFundForm: FormGroup;
  // todo need remove when can get from backend side
  tooltipData = {
    type: 'Type',
    ccy: 'Currency',
    amount: 'Amount',
    note: 'Note',
    investorId: 'Investor',
    faceValue: 'Face Value',
    date: 'Date',
    product: 'Product',
  };
  isSubmitted = false;
  noteText = 'These funds will be remitted to:';
  iban = 'IBAN';
  private changeSubscription: Subscription = new Subscription();
  type: { id: string; name: string }[];
  WithdrawFundData: WithdrawFund;
  allCurrencyBankAccountData: any = [];
  investors: { id: string; name: string }[];
  product: { id: string; name: string }[];
  allCcyData: { id: string; name: string }[];
  intermediaryId = INTERMEDIARY_ID;
  investorId: string;
  financialStatementData: any[] = [];
  currentDate = moment().format('YYYY-MM-DD');
  constructor(
    private withdrawFundsHttpService: WithdrawFundsHttpService,
    private investmentsHttpService: InvestmentsHttpService,
    private investorsHttpService: InvestorsHttpService,
    private withdrawFundsFormProvider: WithdrawFundsFormProvider,
    public activeModal: NgbActiveModal,
    private config: NgbDatepickerConfig,
    private http: HttpService,
    private route: ActivatedRoute
  ) {
    this.initDatePickerConfig();
    this.initializeIntermediaryId();
  }

  ngOnInit(): void {
    this.investorId = this.route.snapshot.queryParamMap.get('id') || '';
    this.WithdrawFundForm = this.withdrawFundsFormProvider.initWithdrawFundsForm(this.WithdrawFundData);
    if (this.investorId) {
      this.WithdrawFundForm.get('investorId')?.setValue(this.investorId);
      this.getInvestorById(this.investorId);
      this.getInvestmentsById(this.investorId);
      this.setPrePopulatedValue();
    }
    this.getDropDownsData();
    this.subscribeToValueChanges();
  }

  initializeIntermediaryId(): void {
    const storedData = localStorage.getItem('userData');
    const userDetails = storedData ? JSON.parse(storedData) : {};
    this.intermediaryId = userDetails.entityRoleId || null;
  }

  onSubmit() {
    const fieldsToFormat = ['date'];
    this.isSubmitted = true;
    if (this.WithdrawFundForm.invalid) {
      return;
    }
    let rawData = this.WithdrawFundForm.value;
    rawData = getFormData(rawData);
    fieldsToFormat.forEach((field) => {
      const date = rawData[field];
      if (date) {
        rawData[field] = field === 'months' ? moment(date).format('MM') : moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
      }
    });
    rawData.intermediaryId = this.intermediaryId.toString();
    rawData.investorId = rawData.investorId.toString();
    rawData.faceValue = Number(rawData.faceValue.replace(/,/g, ''));
    this.doCreate(rawData);
  }

  onSelect(event:any): void {
    if (event?.id) {
      this.getInvestorById(event.id);
    } else {
      this.iban = 'IBAN';
    }
  }

  doCreate(data: any): void {
    this.investmentsHttpService.postData('investments', data).subscribe({
      next: (response: any) => {
        this.closeModal();
        this.WithdrawFundForm.reset();
        Swal.fire({
          icon: 'success',
          text: 'Investment successfully added.',
          showConfirmButton: false,
          timer: 5000,
        });
      },
      error: (error) => {
        let errorMessages = error.error.message;
        if (typeof errorMessages === 'string') {
          errorMessages = [errorMessages];
        }
        const containsNoReceiptError = errorMessages.some((msg: string | string[]) => msg.includes('There is no receipt investment'));
        const containsFutureDateError = errorMessages.some((msg: string | string[]) => msg.includes('maximal allowed date for'));
        const defaultMessage = containsNoReceiptError || containsFutureDateError
          ? 'Error'
          : errorMessages.join(', ');
        Swal.fire({
          icon: 'error',
          text: defaultMessage,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
      },
    });
  }

  private getDropDownsData(): void {
    this.withdrawFundsHttpService.getFilterData().subscribe((investmentsFilterData) => {
      investmentsFilterData.types.sort((a, b) => a.localeCompare(b));
      this.type = [
        ...(investmentsFilterData.types.map((type) => ({
          id: type,
          name: type,
        })) || []),
      ];

      this.product = [
        ...(investmentsFilterData.products.map((product) => ({
          id: product,
          name: product,
        })) || []),
      ];

      this.investmentsHttpService.getInvestorsFilterData().subscribe((investor: any) => {
        this.investors = [
          ...(investor.items
            .sort((a: any, b: any) => {
              const nameA = a.entity?.name.toUpperCase();
              const nameB = b.entity?.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            })
            .map((investor: any) => ({
              id: investor.id,
              name: `${investor.id}- ${investor?.entity?.name || ''}`,
            })) || []),
        ];
      });
    });
    this.http.getCurrencyWithBank().subscribe((response: any) => {
      this.allCcyData = [
        ...(response.map((ccy: any) => ({
          id: ccy.currency,
          name: ccy.currency,
        })) || []),
      ];
    });
  }

  getInvestorById(id: string) {
    this.investorsHttpService.getInvestorById(id).subscribe({
      next: (response: InvestorsByIdResponseInterface) => {
        if (response.bankAccounts && response.bankAccounts.length > 0) {
          this.iban = response.bankAccounts[0].iban;
        } else {
          this.iban = 'IBAN';
          Swal.fire({
            icon: 'error',
            text: 'No IBAN found for the investor.',
            showConfirmButton: true,
            confirmButtonText: 'Ok',
          });
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  private getCurrentDate(): NgbDate {
    const today = new Date();
    return new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
  }

  initDatePickerConfig() {
    const currentDate = this.getCurrentDate();
    this.config.minDate = { year: 1970, month: 1, day: 1 };
    this.config.maxDate = currentDate;
  }

  subscribeToValueChanges() {
    this.changeSubscription.add(
      this.WithdrawFundForm.get('faceValue')?.valueChanges.subscribe((faceValue) => {
        if (!faceValue) {
          this.WithdrawFundForm.get('faceValue')?.setErrors({ required: true });
        } else if (+faceValue > 0) {
          this.WithdrawFundForm.get('faceValue')?.setErrors(null);
        } else {
          this.WithdrawFundForm.get('faceValue')?.setErrors({ invalidFaceValue: true });
        }
      }) as Subscription,
    );
  }

  getInvestmentsById(investorId: string) {
    const queryParams = {
      perPage: 10000,
      investorId: investorId
    }
    this.investorsHttpService.getInvestmentsData(queryParams).subscribe({
      next: (response: InvestmentsResponseInterface) => {
        this.WithdrawFundForm.get('product')?.setValue(
          this.getUniqueValue(response.items, "product") === 'Multiple' ? '' : this.getUniqueValue(response.items, "product")
        );
      }
    });
  }

  setPrePopulatedValue() {
    const data = localStorage.getItem('financialStatementData');
    if (data) {
      this.financialStatementData = JSON.parse(data);
      if (this.financialStatementData && this.financialStatementData.length > 0) {
        const { years: year, months: month, date: day } = moment(this.currentDate).toObject();
        this.WithdrawFundForm.get('date')?.setValue({ year, month: month + 1, day });
        const ccy = this.getUniqueValue(this.financialStatementData, "ccy");
        const type = this.getUniqueValue(this.financialStatementData, "type");
        if (type === 'Multiple') {
          this.WithdrawFundForm.get('type')?.setValue('');
        } else {
          const months = type === 'Demand' || type === 'Fixed' ? 12 : type === 'Term' ? 36 : null;
          if (months !== null) this.WithdrawFundForm.get('months')?.setValue(months);
          this.WithdrawFundForm.get('type')?.setValue(type);
        }
        this.WithdrawFundForm.get('ccy')?.setValue(ccy === 'Multiple' ? '' : ccy);
      }
    }
  }

  private getUniqueValue(data: any[], key: string) {
    const uniqueValues = [...new Set(data.map((item) => item[key]))];
    return uniqueValues.length > 1 ? "Multiple" : uniqueValues[0];
  }
}
